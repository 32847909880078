.btn {
    position: relative;
    display: inline-block;
    // padding: 1rem 1.5rem;
    // height: calc(1.5em + 2rem + 2px);
    height: calc( 1.5em + 2 * #{$x_padding} + 2px ); // font + x padding + border
    padding: $x_padding 1.5rem;
    @include font-size(16px);
    text-align: center;
    font-family: $sans-serif;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-decoration: none !important;
    color: $white;
    border: 1px solid $white !important;
    // border-radius: calc((1.5em + 2rem + 2px / 2);
    border-radius: 0;
    text-transform: uppercase;
    white-space: nowrap;
    @include transition(all 0.5s ease);

    &:focus,
    &.focus,
    &:visited,
    &.visited {
        // box-shadow: 0 0 5px 0 rgba($black, 0.25);
        box-shadow: unset;
    }
}

.btn-rnd {
  // border-radius: calc((1.5em + 2rem + 2px / 2);
  border-radius: 10px;
}

.btn:hover, .btn:active, .btn:visited {
    color: $white;
    text-decoration:none !important;
}

.btn-sm {
    padding: calc(#{$x_padding} - 3px) 0.75rem;
    height: auto;
    font-size:em(10px);
}

.btn-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    @include font-size(20px);
}

.btn-block {
    display: block;
    text-align: center;
    width: 100%;
    white-space: normal;
    line-height: 1.1;
    height: auto;
}

.btn-serif {
    @include font-size(24px); // bump font size for serifs
    font-family: $serif;
    font-weight: 400;
}

.btn-default { 
    border-color: $brand-primary; 
    background-color: $brand-primary; 
}
.btn-dark, .btn-dark:focus { 
    background-color: $green !important; 
    border-color: $green !important; 
    color: $white !important; 
}
.btn-red, .btn-red:focus { 
    background-color: $red !important; 
    border-color: $red !important; 
    color: $white !important; 
}
.btn-green, .btn-green:focus { 
    background-color: $green !important; 
    border-color: $green !important; 
    color: $white !important; 
}
.btn-hlw, .btn-hlw:focus,
.btn-hlw-light, .btn-hlw-light:focus { 
    border-width: 1px !important; 
    background-color: transparent !important; 
    border-color:$green !important; 
    color:$green !important; 
}
.btn-hlw-dark, .btn-hlw-dark:focus { 
    border-width: 1px !important; 
    background-color: transparent !important; 
    border-color:$white !important; 
    color:$white !important; 
}
.btn-hlw-green, .btn-hlw-green:focus { 
    border-width: 1px !important; 
    background-color: transparent !important; 
    border-color: $green !important; 
    color: $green !important; 
}
.btn-light, .btn-light:focus,
.btn-white, .btn-white:focus { 
    background-color: $white !important; 
    border-color: $green !important; 
    color: $green !important; 
}

.btn-light:hover, .btn-light:active,
.btn-white:hover, .btn-white:active,
.btn-default:hover, .btn-default:active, .btn-default.active, 
.btn-red:hover, .btn-red:active, .btn-red.active, 
.btn-green:hover, .btn-green:active, .btn-green.active, 
.btn-dark:hover, .btn-dark:active, .btn-dark.active, 
.btn-hlw:hover, .btn-hlw:active, .btn-hlw.active, .btn-hlw.is-checked,
.btn-hlw-green:hover, .btn-hlw-green:active, .btn-hlw-green.active, .btn-hlw-green.is-checked,
.btn-hlw-light:hover, .btn-hlw-light:active, .btn-hlw-light.active, .btn-hlw-light.is-checked,
.btn-hlw-dark:hover, .btn-hlw-dark:active, .btn-hlw-dark.active, .btn-hlw-dark.is-checked {
    background-color: $black !important; 
    border-color: $black !important; 
    color: $green !important; outline: 0;
    box-shadow: unset;
}

// hover plus/minus icon
@mixin plusMinus($width, $lineWidth, $color) {
  box-sizing: border-box;
  transition: transform 0.3s;
  width: $width;
  height: $width;
  transform: rotate(180deg);
  position: relative;

  &:hover {
    transform: rotate(0deg);
  }
  &::before {
    content: '';
    display: block;
    width: $width;
    height:0px;
    border-bottom: solid $lineWidth $color;
    position: absolute;
    bottom:$width /2 - $lineWidth/2;
    transform: rotate(90deg);
    transition: width 0.3s;
  }
  &:hover::before {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-bottom: solid $lineWidth $color;
    position: absolute;
    bottom: $width /2 - $lineWidth/2;
    transform: rotate(90deg);
  }
  &::after {
    content: '';
    display: block;
    width: $width;
    height:0px;
    border-bottom: solid $lineWidth $color;
    position: absolute;
    bottom:$width /2 - $lineWidth/2;
  }
}

.pm-icon {
  @include plusMinus(24px, 2px, $light-gray);
}

// wooverides
.button {
    @extend .btn;
    @extend .btn-hlw;
}

.input-text {
    @extend .form-control;
}