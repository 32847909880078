// gallery carousel
.carousel-indicators {
  @include margin-bottom(12px);
  flex-flow: row wrap;

  [data-bs-target] {
    display: none;
    
    @include media-breakpoint-up(lg){
      display: flex;
      @include margin-bottom(4px);
      // width: 8px;
      // height: 8px;
      background-color: transparent;
      border: 0;
      border: 1px solid $white;
      border-radius: 50%;
      opacity: 1;
      @include transition(all .6s ease);

      width: 10px;
      height: 10px;
    }
  }

  .active {
    background-color: $white;
    border-color: $green;
  }
}

$carousel-control-color: $white;
$carousel-control-width: 34px;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38.09725 63.32243' fill='#{$carousel-control-color}'><polygon points='31.662 63.322 38.097 56.887 12.871 31.662 38.097 6.436 31.662 0 0 31.662 31.662 63.322'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 38.09725 63.32243' fill='#{$carousel-control-color}'><polygon points='6.436 63.322 0 56.887 25.226 31.662 0 6.436 6.436 0 38.097 31.662 6.436 63.322'/></svg>");
$carousel-transition-duration: .6s;
// Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$carousel-transition: transform $carousel-transition-duration ease; 

.carousel-control-next, 
.carousel-control-prev {
  width: 10%;
}

.carousel-control-prev-icon {
  background-image: $carousel-control-prev-icon-bg;
}

.carousel-control-next-icon {
  background-image: $carousel-control-next-icon-bg;
}