header.banner {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  height: $navigation-height-sm;
  background-color: $white;
  box-shadow: 0 0 0 rgba($black,0.6);
  z-index: 998;
  @include transition(all 0.25s ease);
  border-bottom: 7px solid $green;
    
  @include media-breakpoint-up(xl){
    background-position: -10vw 0;
    height: $navigation-height;
  }

  @media (min-width: 1200px){
    background-position: -14vw 0;
  }

  @media (min-width: 1600px){
    background-position: -3vw 0;

  // @include media-breakpoint-up(xl){
    // background-position: calc( 100vw * 1/1920 * 144 * -1 ) 0;
    // 1920
    // 3vw = 57.6px
    // 58 of 300 = 19.33333%
    // 1200
    // 12vw = 144px
    // 144 of 300 = 48

    height: $navigation-height;
  }

  .container-fluid {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .brand {
    margin: 0 0 0 15px;
    width: 139px;
    height: 34px;
    text-indent: -999em;
    background-image: url(../images/ghc-logo-light.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    z-index: 101;
    // @include transition(all 0.2s ease);
    
    @include media-breakpoint-up(xl) {
      margin-left: 8.33333333vw;
      width: 217px;
      height: 53px;
    }
  }

  // navburger
  .navbar-toggle {
    z-index: 999;
    // position: absolute;
    // left: 15px;
    // top: (($navigation-height-sm - 21px) / 2); // 21px = navburger height

    @include media-breakpoint-up(xl){
      display: none;
      // left: 25px;
      // top: calc((#{$navigation-height} - 21px)/2);
    }
  }

  .hamburger {
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function;
    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    // margin: 0;
    overflow: visible;

    &:active, 
    &:focus {
    outline: 0;
    border: 0;
    }
    
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-hover-filter;
      }
      @else {
        // opacity: $hamburger-hover-opacity;
      }
    }
  }

  .hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: $hamburger-layer-color;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  }

  /*
   * Squeeze
   */
  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::before {
        transition: top 0.1s 0.14s ease, opacity 0.1s ease;
      }

      &::after {
        transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: rotate(45deg);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition: top 0.1s ease, opacity 0.1s 0.14s ease;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }
}

.nav-primary {
  display: none;
  position: absolute;
  top: $navigation-height-sm;
  left: 0;
  width: 100%;
  min-height: calc(100vh - #{$navigation-height-sm});
  z-index: 998;
  opacity: 0;
  @include transition(all 0.5s ease);

  @include media-breakpoint-up(xl){
    display: block;
    position: relative;
    top: unset;
    left: unset;
    width: auto;
    height: auto;
    min-height: unset;
    opacity: 1;
  }

  &:not(.is-active) {
    
    #menu-main-navigation {
      // display: none;
    }
  }

  &.show {
    display: flex;
    opacity: 1;
  }

  #menu-main-navigation {
    display: flex !important;
    flex-flow: column nowrap;
    padding-top: calc(#{$navigation-height-sm / 2});
    // min-height: 100vh !important;
    // height: 100vh !important;
    // position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    height: auto;
    background-color: rgba($gray, 0.9);
    z-index: 998;
    @include transition(all 0.5s ease);

    @include media-breakpoint-up(xl){
      padding: 0;
      opacity: 1;
      // display: flex !important;
      flex-flow: row nowrap;
      min-height: unset !important;
      height: auto !important;
      position: relative;
      width: auto;
      background-color: unset;
    }

    &.collapse:not(.show) {
      // display: unset!important;
    }

    // &.show,
    // &.collapsing, {
    //   display: flex;
    //   opacity: 1;
    //   position: fixed;
    //   height: 100vh;
    //   justify-content: center;
    //   align-items: center;
    //   flex-flow: column nowrap;
    //   z-index: 997;
    // }

    li {
      position: relative;
      margin: 0;
      padding: 0;
      text-align: center;
      box-shadow: inset 0 0 $green; // green bar
      @include transition(all .25s ease);

      a {
        font-family: $sans-serif;
        @include font-size(24px);
        line-height: 1.618;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;
        letter-spacing: normal;
        @include transition(all .25s ease);

        @include media-breakpoint-up(xl){
          color: $gray;
          @include font-size(18px);
          line-height: $navigation-height-sm;
        }

        &:hover {
          // color: rgba($white, 0.5);
          color: $green;
          text-decoration: none;
        }

        @include media-breakpoint-down(lg){

          &.nogo {
            display: none;
            height: 0;
            overflow: hidden;
          }
        }

        @include media-breakpoint-up(xl){
          padding: 9px 15px 7px;
          line-height: $navigation-height;
        }
      }

      .sub-menu {
        margin:0;
        padding: 0;
        list-style: none;
        
        @include media-breakpoint-up(xl){
          min-width: 250px;
          display: none;
          position: absolute;
          // bottom: 10px;
          // right: -25px; // li margin above
          left: 0; 
          height: 0;
          z-index: 999;
          opacity: 0; // http://jsfiddle.net/hashem/9dsGP/2/
          width: auto;
          background-color: $white;
          @include transition(all .25s ease);
          box-shadow: 0 0 10px rgba($black, 0.2);
        }

        li {
          margin: 0;
          padding: 0;
          
          @include media-breakpoint-up(xl){
            white-space: nowrap;
            padding: 5px 15px;
            text-align: left;

            &:hover {
              background-color: $green;
            }
          }

          a {
            @include font-size(24px);
            color: $gray;
            
            @include media-breakpoint-up(xl){
              @include font-size(18px);
              color: $gray;
              display: block;
              line-height: 1.3;
            }

            &:hover {
              color: $white;
            }
          }
        }
      } // END .sub-menu

      @include media-breakpoint-up(xl){
        &:hover .sub-menu {
          display: block;
          opacity: 1;
          height: auto;

          li {
            // background-color: transparent;
            box-shadow: unset;
          }
        }
      }

      &:hover,
      &.active {
        // background-color: $off-white;
        // box-shadow: inset 0 -5px $green; // green bar

        a {
          color: $green;
        }
      }
    }
  }


  /* 
  // active menu
  &.is-active {
    #menu-main-navigation {
      display: flex;
      opacity: 1;
      position: fixed;
      width: 100vw;
      height: 100vh;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      z-index: 997;

      li,
      li.active {
        box-shadow: unset;

        a {
          padding: 0 0.5rem;
          display: block;
          text-align: center;
          color: $white !important;
          font-size: em(32px) !important; // moldy oldie here.
        }

        ul li a {
          text-transform: capitalize;
          font-size: em(24px) !important; // moldy oldie here.
        }   
      }
    }
  } 
  */
}
