footer.content-info {
  @include margin-top(20px);
  @include padding-top(74px);
  @include padding-bottom(36px);
  background-color: $black;

  .footer-row {
    display: flex;
    flex-flow: column nowrap;

    @include media-breakpoint-up(lg){
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .footer-logo {
     
    @include media-breakpoint-up(lg){
      margin-right: 10vw;
      // max-width: 12vw;
    }

    h2 {
      margin: 0 auto 44px;
      width: 217px;
      height: 53px;
      text-indent: -999em;
      background-image: url(../images/ghc-logo-dark.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0 0;
      
      @include media-breakpoint-up(lg){
        margin: 0;
        width: 250px;
        height: 61px;
      }
    }
  }

  .footer-contact {
    text-align: center;

    @include media-breakpoint-up(lg){
      text-align: right;
    }
  }

  .social-icons {
    @include margin-bottom(15px);
  }

  .contact-info,
  .social-info {
    @include font-size(15px);
    line-height: 1.0;
    color: $white;
    font-family: $sans-serif;
    text-align: center;

    @include media-breakpoint-up(lg){
      @include font-size(20px);
      text-align: left;
    }

    a {
      color: $green;

      &:hover {
        color: $white;
      }
    }

    .webicon {
      @include font-size(24px);
      color: $green;
    }
  }

  .legal {
    @include margin-top(15px);
    @include font-size(14px);
    color: $light-gray;
    text-align: center;
    
    @include media-breakpoint-up(lg){
      text-align: right;
    }
  }

  .footer-signup {
    @include margin(25px auto 60px);
      
    @include media-breakpoint-up(md){
      width: 400px;
    }
  
    @include media-breakpoint-up(lg){
      margin-top: 0;
      width: auto;
    }

    form {
      width: 100%;
    }

    .mc4wp-form-fields {
      display: flex;
    }

    .mc4wp-response {
      @include margin-top(15px);
      @include padding-left(12px);

      * {
        color: $green;
      }
    }

    h5 {
      @include margin-bottom(20px);
      @include font-size(14px);
      color: $white;
      line-height: 1.1;
      font-weight: 400;
      font-family: $serif;
      text-transform: uppercase;
    }

    input[type=email] {
      border-radius: 0;
      border-color: $gray;
      background-color: transparent;
      color: $white;
    }
  }

  .nav-footer {

    ul#menu-footer-navigation {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row wrap;

      li {
        margin: 0 24px 24px 0;
        padding: 0;

        a {
          color: $gray;
          font-family: $sans-serif;
          text-transform: uppercase;
          @include font-size(18px);

          &:hover {
            color: $white;
          }
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .social-icons {
    list-style: none;
    @include margin(0 0 5px);
    padding: 0;
    display: inline-flex;
    justify-content: center;

    @include media-breakpoint-up(lg){
      justify-content: flex-start;
    }

    li {
      padding: 0 0 0 20px;

      a {
        @include transition(all 0.5s ease);

        .webicon {
          color: $green;
          @include font-size(20px);
        }

        &:hover {
          .webicon {
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
  }
}
