// Search form
.search-form {
  // @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  // @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// bootstrap overrides
.comment-form input[type=email], 
.comment-form input[type=text], 
.comment-form input[type=url], 
.comment-form textarea, 
.form-control, 
.search-form .search-field {
  padding: 0.5rem 0.75rem;
  font-family: $sans-serif;
  @include font-size(16px);
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05rem;
  color: $white;
  border: 1px solid $green;
  border-radius: 0;
  @include transition(color .2s ease, border-color .2s ease, box-shadow .2s ease);
}

.form-control,
.comment-form {

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-ms-input-placeholder {
    color: $green;
  }
}

::placeholder,
::-webkit-input-placeholder,
::-ms-input-placeholder {
  color: $green;
}

.comment-form input:focus[type=email], 
.comment-form input:focus[type=text], 
.comment-form input:focus[type=url], 
.comment-form textarea:focus, 
.form-control:focus, 
.form-select:focus, 
.search-form .search-field:focus {
  color: $green;
  background-color: $white;
  border-color: $green;
  outline: 0;
  box-shadow: 0 0 0 0 $light-gray;
}

.form-select {
  padding: 0.5rem 2.25rem 0.5rem 0.75rem;
  font-family: $sans-serif;
  @include font-size(16px);
  color: $light-gray;
  border: 1px solid $light-gray;
  border-radius: 0;
  @include transition(color .2s ease, border-color .2s ease, box-shadow .2s ease);
}

label {
  font-family: $sans-serif;
  @include font-size(13px);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 1.5;
  color: $light-gray;
}

.input-group {
  flex-wrap: nowrap;

  input[type=text],
  input[type=email],
  input[type=url] {
    border-right-width: 0;
  }

  .wpcf7-form-control-wrap + input.btn {
    border-left-width: 0;
  }
}

// contact form 7
.wpcf7 {

  .screen-reader-response {
    @extend .visually-hidden;
  }

  input.wpcf7-not-valid {
    color: $alert-red;
    border-color: $alert-red;

    &:focus {
      color: $black;
    }
  }

  span.wpcf7-not-valid-tip {
    @include font-size(13px);
    font-family: $sans-serif;
    font-weight: 400;
    color: $alert-red;
    line-height: 1.3;
  }

  .wpcf7-response-output {
    @include padding(10px);
    @include margin-top(16px);
    height: 0;
    @include font-size(13px);
    font-family: $sans-serif;
    font-weight: 400;
    color: $white;
    border: 1px solid $light-gray;
    background-color: $light-gray;
    @include transition(all 0.5s ease);
  }

  .wpcf7-form {

    &.init {

      .wpcf7-response-output {
        // height: 0;
        opacity: 0;
        // @include transition(all 0.5s ease);
      }
    }

    &.submitting {

     .wpcf7-response-output {
        height: 0;
        opacity: 0;
      } 
    }

    &.sent {

      .wpcf7-response-output {
        height: auto;
        opacity: 1;
      }
    }
    
    &.invalid,
    &.failed {

      .wpcf7-response-output {
        height: auto;
        opacity: 1;
        color: $white;
        border-color: $alert-red;
        background-color: $alert-red;
      }
    }
  }
}
