// global page styles
$banner-height-sm: 33vh;
$banner-height: 40vh;
.page-banner {
  @extend .full-width;
  // margin-bottom: 0;
  @include margin-bottom(48px);
  height: $banner-height-sm;
  background-position: 50% 0;
  background-size: cover;
  position: relative;
  z-index: 8;

  @include media-breakpoint-up(lg){
    height: $banner-height;
  }

  .banner-content {
    // width: 100%;
    // height: $banner-height-sm;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    z-index: 10; // +1 over overlay
    position: absolute;
    right: 1.5rem;
    bottom: 1.5rem;

    @include media-breakpoint-up(lg){
      // height: $banner-height;
      right: 3rem;
      bottom: 2.5rem;
    }
    
    h1.page-title {
      margin: 0;
      font-family: $sans-serif;
      @include font-size(54px);
      line-height: 1.1;
      font-weight: 400;
      color: $white;
      // text-transform: uppercase;
      // transform: translateY(-15px);
      // text-align: center;
      text-shadow: 0 0 10px rgba($black,0.5);
    }
  }
}

.page-banner-headline {
  @extend .full-width;
  margin-top: 0;
  @include margin-bottom(44px);
  @include padding(25px 0);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // background-color: $green;

  h2 {
    margin-bottom: 0;
    color: $green;
    @include font-size(34px);
    text-align: center;
  }
}

// no banner, how about just a header then.
.page-header {
  @include margin(100px 0 44px);
  text-align: center;
}

// overlay for images, parent container must have position:relative children must have z-index > 2
.oscuro {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-image: url(../images/banner-overlay.svg);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  // background-color: rgba($black,0.3);
  // background: linear-gradient( to top, rgba($blue, 0.8), rgba($blue, 0) 40%, rgba($blue, 0) );
  z-index: 9; // one less than home slide headlines
}

$margin-sm: 25px;
$margin:    100px;

.section {
  position: relative;
}

$height-sm: 66vh;
$height-lg: calc(100vh - #{$navigation-height} - 125px);


// home page intro banner
// .home-page-banner {
//   @include margin-bottom(48px);
//   z-index: 8;
// }

// sidebar widget with content and button
.sdbr-wrap {
  @include margin-bottom(16px);
  @include padding(36px);
  border: 2px solid $green;
  border-radius: 15px;
  text-align: center;

  h3 {
    @include margin-bottom(16px);
    color: $green;
    line-height: 1;
  }

  p {
    @include margin-top(16px);
    line-height: 1.1;
  }

  *:last-of-type {
    margin-bottom: 0;
  }
}

// gallery page
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  @include media-breakpoint-up(lg){
    grid-template-columns: repeat(4, 1fr);
  }

  & > div {
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%; 
      object-position: 50% 50%;
      object-fit: cover;
    }
  }
}

